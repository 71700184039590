<template></template>
<script setup lang="ts">
import type { NuxtError } from '#app'

const logger = useLogger();
const props = defineProps<{ error: NuxtError }>();
const route = useRoute();
const { t } = useI18n();
const toast = useToast();

if (props.error.statusCode === 404) {
    logger.error(`Landed on a page that doesn't exist`, props.error);
    const { path } = (props.error.data as { path: string });
    toast.warning({
        message: t('messages.page-not-found', { page: path.replace('/', '') }),
        duration: 2000
    });
    clearError({ redirect: route.redirectedFrom?.path || '/' });
} else {
    logger.error(`Some other kind of error`, props.error);
    toast.error(t('messages.error'), {
        timeout: 2000
    });
    clearError({ redirect: '/' });
}


</script>
<i18n lang="json" locale="en">{
    "messages": {
        "page-not-found": "Page '{page}' doesn't exist",
        "error": "An error occurred, redirecting back to home page"
    }
}</i18n>