export const useDbStore = () => {
    const dbStoreBuilder = defineStore('db', () => {
        //#region Store Initializers
        const logger = useLoggerWithoutTags().withTag(`db-store`);
        const db = useSupabaseClient<Database>();

        const user = useSupabaseUser();
        const profile = ref<Profile>();
        const family = ref<Family>();
        const members = ref<Member[]>();
        //#endregion

        //#region Methods
        
        
        const setup = async () => {
            if (user.value && !profile.value) {
                await fetchProfile();
            }

            if (!profile.value) {
                logger.error(`Cannot retreieve profile`)
                return;
            };

            const inviteCode = import.meta.client ? sessionStorage.getItem('invite-code') || null : null;

            if (profile.value.is_initialized == true) {
                logger.debug('Profile already initialized, fetching family and members...');

                try {
                    await db.auth.updateUser({
                        data: {
                            family_id: profile.value.family_id
                        }
                    });

                    await db.auth.refreshSession();
                    await Promise.all([fetchFamily(), fetchMembers()]);

                    logger.debug('Successfully retrieved family and members.')
                } catch (error) {
                    logger.error(error);
                }
                return;
            }

            profile.value.is_initialized = true;
            const fullName: string = user.value?.user_metadata?.name;
            const givenName: string = user.value?.user_metadata?.givenName;
            profile.value.full_name = fullName;

            logger.debug('Fetching `New Family RFC Call`...');
            const familyUpdateResponse = await db.rpc('new_family');
            if (familyUpdateResponse.error) {
                logger.error(familyUpdateResponse.error);
            } else {
                logger.debug(`Successfully updated profile`);
                const family_id = familyUpdateResponse.data;
                logger.debug(`Successfully updated profile :: fam id`, family_id);
                profile.value.family_id = family_id;
                family.value = {
                    display_name: '',
                    id: family_id,
                    created_at: new Date().toISOString()
                }
            }

            await db.auth.updateUser({
                data: {
                    family_id: family.value?.id!
                }
            });

            await db.auth.refreshSession();

            if (family.value) profile.value.family_id = family.value.id;

            logger.debug('Update Profile...');
            const profileUpdateResponse = await db.from('profiles').update(profile.value).eq('id', profile.value.id).select().single();

            if (profileUpdateResponse.error) {
                logger.error(profileUpdateResponse.error);
            } else {
                logger.debug(`Successfully added new family to profile`);
                profile.value = profileUpdateResponse.data;
            }

            logger.debug('Create initial family member...');
            const initialMemberResponse = await db.rpc('new_initial_member', {
                family_id: profile.value.family_id || family.value!.id,
                name: givenName || fullName!,
                profile_id: profile.value.id
            });

            if (initialMemberResponse.error) {
                logger.error(initialMemberResponse.error);
            } else {
                logger.debug(`Successfully updated initial member on profile`);

                logger.debug('Getting all the family members...')
                const fullMembersResponse = await db.from('members').select().eq('family_id', family.value!.id);
                if (fullMembersResponse.error) {
                    logger.error(fullMembersResponse.error);
                } else {
                    logger.debug('Got all the family members');
                    members.value = fullMembersResponse.data;
                }
            }

            if (!profile.value) return;
            profile.value.is_initialized = true;

        }

        const fetchProfile = async (): Promise<void> => {

            if (!user.value) {
                logger.debug(`No authorized user, aborting profile fetch`);
                return;
            }

            logger.start(`Fetching..`, { supabaseUser: { ...user } });

            const resp = await db.from('profiles')
                .select('*')
                .eq('id', user.value?.id)
                .single();


            if (resp.error) {
                logger.fail(resp.error);
                return;
            } else {
                logger.success(`Received`);
                logger.debug(`Data`, { ...resp.data });
                profile.value = resp.data;
            }

            if (profile.value?.is_initialized == true) {
                logger.debug('Profile initialized, getting family and members...');

                try {
                    await Promise.all([fetchFamily(), fetchMembers()]);
                    await db.auth.updateUser({
                        data: {
                            family_id: profile.value.family_id
                        }
                    });
                    await db.auth.refreshSession();
                    logger.debug('Successfully completed');
                } catch (error) {
                    logger.error(error);
                }

            }
        }

        const fetchFamily = async () => {
            logger.start('Fetching...', { profile: { ...profile.value } });

            const resp = await db.from('families')
                .select()
                .eq('id', profile.value!.family_id!)
                .single();

            if (resp.error) {
                logger.fail(resp.error);
                return;
            } else {
                logger.success('Received');
                logger.debug({ ...resp.data });
                family.value = resp.data;
            }
        }

        const fetchMembers = async () => {
            logger.start('Fetching...', { profile: { ...profile.value } });

            const resp = await db.from('members')
                .select('*')
                .eq('family_id', profile.value!.family_id!);


            if (resp.error) {
                logger.fail(resp.error);
                return;
            } else {
                logger.success('Received');
                logger.debug({ ...resp.data });
                members.value = resp.data;
            }
        }

        const getMemberDisplayName = (member?: Member) => {
            if(member == null || member == undefined) return '';
            return member.title || member.name;
        }

        const saveFamily = async () => {
            logger.debug('SAVING FAMILY');

            const familyCommitResp = await db.from('families').upsert(family.value!).select().single();
            if (familyCommitResp.error) {
                logger.error(`There was an error trying to update the family`, familyCommitResp.error);
            } else {
                logger.debug(`Successfully updated family info`, familyCommitResp.data);
                family.value = familyCommitResp.data;
            }

            const membersCommitResp = await db.from('members').upsert(members.value!).select();
            if (membersCommitResp.error) {
                logger.error(`There was an error trying to update the members`, membersCommitResp.error);
            } else {
                logger.debug(`Successfully updated members`, membersCommitResp.data);
                members.value = membersCommitResp.data;
            }

            if (!profile.value) return;
            profile.value.is_setup = true;

            return await updateProfile();
        }

        const updateProfile = async () => {
            const updateProfileResp = await db.from('profiles').update(profile.value!).eq('id', profile.value!.id).select().single();

            if (updateProfileResp.error) {
                logger.error(`There was an error trying to update profile`, updateProfileResp.error);
            } else {
                logger.debug(`Successfully updated profile`, updateProfileResp.data);
                profile.value = updateProfileResp.data;
            }
            return;
        }

        const addMember = async () => {
            const newMemberResp = await db.from('members').insert({ family_id: family.value!.id, name: '', profile_id: profile.value?.id }).select().single();

            if (newMemberResp.error) {
                logger.error('There was an error trying to update the members', newMemberResp.error);
            } else {
                logger.debug(`Successfully updated members`, newMemberResp.data);
                members.value?.push(newMemberResp.data);
            }

            return;
        }

        const removeMember = async (member: Member) => {
            const removeMemberResponse = await db.from('members').delete().eq('id', member.id).select();

            if (removeMemberResponse.error) {
                logger.error(`There was an error trying to remove member ${member.name}/${member.id}`);
            } else {
                logger.debug(`Successfully removed member ${member.name}/${member.id}`);
                members.value = members.value?.filter(f => f.id !== member.id);
            }

            return;
        }

        const awaiter = (): Promise<void> => new Promise(async (resolve) => {
            await fetchProfile();
            return resolve();
        });

        //#endregion


        return {
            saveFamily,
            updateProfile,
            addMember,
            removeMember,

            getMemberDisplayName,

            setup,
            awaiter,

            user,

            profile,
            family,
            members
        }
    }, { persist: { key: 'profile', storage: persistedState.localStorage } });

    return dbStoreBuilder();
}



