export enum ThemeNameEnum {
    'light' = 'light',
    'dark' = 'dark',
    'cupcake' = 'cupcake',
    'bumblebee' = 'bumblebee',
    'emerald' = 'emerald',
    'corporate' = 'corporate',
    'synthwave' = 'synthwave',
    'retro' = 'retro',
    'cyberpunk' = 'cyberpunk',
    'valentine' = 'valentine',
    'halloween' = 'halloween',
    'garden' = 'garden',
    'forest' = 'forest',
    'aqua' = 'aqua',
    'lofi' = 'lofi',
    'pastel' = 'pastel',
    'fantasy' = 'fantasy',
    'wireframe' = 'wireframe',
    'black' = 'black',
    'luxury' = 'luxury',
    'dracula' = 'dracula',
    'cmyk' = 'cmyk',
    'autumn' = 'autumn',
    'business' = 'business',
    'acid' = 'acid',
    'lemonade' = 'lemonade',
    'night' = 'night',
    'coffee' = 'coffee',
    'winter' = 'winter',
    'dim' = 'dim',
    'nord' = 'nord',
    'sunset' = 'sunset'
};

export default defineAppConfig({
    theme: ThemeNameEnum.aqua,
    icon: {
        collections: [
            'ph',
            'logos'
        ],
        provider: 'iconify',
        fallbackToApi: true,
        mode: 'svg',
        class: 'h-6 w-6',
        aliases: {
            'baby-tracker': 'ph:baby-duotone',
            'chores': 'ph:broom-duotone',
            'chores-manage': 'ph:user-list-duotone',
            'rewards': 'ph:trophy-duotone',
            'settings-mine': 'ph:user-gear-duotone',
            'settings-family': 'ph:users-three-duotone',
            'logout': 'ph:sign-out-duotone',
            'info': 'ph:info-duotone',
            'save-changes': 'ph:list-plus-duotone',
            'cancel-changes': 'ph:arrow-counter-clockwise',
            'cancel-back': 'ph:arrow-bend-up-left-duotone',
            'cancel-close': 'ph:x',
            'save-disk': 'ph:floppy-disk-back-duotone',
            'save-disk-fill': 'ph:floppy-disk-back-fill',
            'trash': 'ph:trash-duotone',
            'edit-pencil': 'ph:note-pencil-duotone',
            'kebab': 'ph:dots-three-vertical-bold',
            'add': 'ph:plus-circle-duotone',
            'gear': 'ph:gear-six-duotone',
            'add-remove': 'ph:plus-minus-duotone',
            'overlay-add': 'ph:plus-light',
            'overlay-edit': 'ph:pencil-light',
            'overlay-delete': 'ph:minus-light',
            'overlay-save': 'ph:floppy-disk-light',
            'overlay-manage': 'ph:plus-minus-light',
            'complete': 'ph:check-fat-duotone',
            'dashboard': 'ph:desktop-duotone',

        }
    }
});

export { }