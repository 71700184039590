import { default as baby_45trackerh5tn6vkVwNMeta } from "/workspace/pages/baby-tracker.vue?macro=true";
import { default as addzVK4LYwOX0Meta } from "/workspace/pages/chores/add.vue?macro=true";
import { default as editBNRbJNDYRKMeta } from "/workspace/pages/chores/edit.vue?macro=true";
import { default as index2qU1GjK8sYMeta } from "/workspace/pages/chores/index.vue?macro=true";
import { default as manageUJ9lz3UoPAMeta } from "/workspace/pages/chores/manage.vue?macro=true";
import { default as schedulestOusvLOtJYMeta } from "/workspace/pages/chores/schedules.vue?macro=true";
import { default as choresywo2LPTmc7Meta } from "/workspace/pages/chores.vue?macro=true";
import { default as confirmx5TWqDxzC1Meta } from "/workspace/pages/confirm.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as loginGeKhs9qf7uMeta } from "/workspace/pages/login.vue?macro=true";
import { default as plannerczmVAArXLpMeta } from "/workspace/pages/planner.vue?macro=true";
import { default as _91_46_46_46slug_93TY98ebN56kMeta } from "/workspace/pages/profile/[...slug].vue?macro=true";
import { default as setup8dJ2j91ncJMeta } from "/workspace/pages/profile/setup.vue?macro=true";
import { default as addcvPPFz2RYxMeta } from "/workspace/pages/rewards/add.vue?macro=true";
import { default as edit0ct5DTpPMCMeta } from "/workspace/pages/rewards/edit.vue?macro=true";
import { default as index5xBKfD0lRsMeta } from "/workspace/pages/rewards/index.vue?macro=true";
import { default as rewardsBhhNI5zw0PMeta } from "/workspace/pages/rewards.vue?macro=true";
export default [
  {
    name: "baby-tracker",
    path: "/baby-tracker",
    component: () => import("/workspace/pages/baby-tracker.vue").then(m => m.default || m)
  },
  {
    name: choresywo2LPTmc7Meta?.name,
    path: "/chores",
    meta: choresywo2LPTmc7Meta || {},
    component: () => import("/workspace/pages/chores.vue").then(m => m.default || m),
    children: [
  {
    name: "chores-add",
    path: "add",
    meta: addzVK4LYwOX0Meta || {},
    component: () => import("/workspace/pages/chores/add.vue").then(m => m.default || m)
  },
  {
    name: "chores-edit",
    path: "edit",
    meta: editBNRbJNDYRKMeta || {},
    component: () => import("/workspace/pages/chores/edit.vue").then(m => m.default || m)
  },
  {
    name: "chores",
    path: "",
    meta: index2qU1GjK8sYMeta || {},
    component: () => import("/workspace/pages/chores/index.vue").then(m => m.default || m)
  },
  {
    name: "chores-manage",
    path: "manage",
    meta: manageUJ9lz3UoPAMeta || {},
    component: () => import("/workspace/pages/chores/manage.vue").then(m => m.default || m)
  },
  {
    name: "chores-schedules",
    path: "schedules",
    component: () => import("/workspace/pages/chores/schedules.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirmx5TWqDxzC1Meta || {},
    component: () => import("/workspace/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index9S03gOyIVcMeta || {},
    alias: ["/dashboard"],
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginGeKhs9qf7uMeta || {},
    component: () => import("/workspace/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "planner",
    path: "/planner",
    component: () => import("/workspace/pages/planner.vue").then(m => m.default || m)
  },
  {
    name: "profile-slug",
    path: "/profile/:slug(.*)*",
    component: () => import("/workspace/pages/profile/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "profile-setup",
    path: "/profile/setup",
    meta: setup8dJ2j91ncJMeta || {},
    component: () => import("/workspace/pages/profile/setup.vue").then(m => m.default || m)
  },
  {
    name: rewardsBhhNI5zw0PMeta?.name,
    path: "/rewards",
    meta: rewardsBhhNI5zw0PMeta || {},
    component: () => import("/workspace/pages/rewards.vue").then(m => m.default || m),
    children: [
  {
    name: "rewards-add",
    path: "add",
    component: () => import("/workspace/pages/rewards/add.vue").then(m => m.default || m)
  },
  {
    name: "rewards-edit",
    path: "edit",
    component: () => import("/workspace/pages/rewards/edit.vue").then(m => m.default || m)
  },
  {
    name: "rewards",
    path: "",
    meta: index5xBKfD0lRsMeta || {},
    component: () => import("/workspace/pages/rewards/index.vue").then(m => m.default || m)
  }
]
  }
]