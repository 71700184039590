import routerOptions0 from "/workspace/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
}