import type { RouteLocation } from "#vue-router";

export const useRedirectStore = defineStore('redirect', () => {
    const redirect = ref<RouteLocation | undefined>(undefined);

    const getOnce = () => {
        if (!redirect.value) return undefined;
        const output = { ...redirect.value };
        clear();        
        return output;
    }

    const set = (routeLocation: RouteLocation) => {
        redirect.value = routeLocation;
    }

    const clear = () => redirect.value = undefined;

    return {
        redirect,

        getOnce,    
        set,
        clear
    };
}, {
    persist: {
        storage: persistedState.sessionStorage
    }
});