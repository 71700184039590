import { consola, createConsola } from "consola";

consola.wrapAll();


export const useLoggerWithoutTags = () => {
    const runtimeConfig = useRuntimeConfig();
    return createConsola({
        level: runtimeConfig.public.logLevel,
        formatOptions: {
            colors: true
        }
    })
}

export const useLogger = () => {
    const route = useRoute();
    const tag = route.path;
    const runtimeConfig = useRuntimeConfig();
    const logger = createConsola({
        level: runtimeConfig.public.logLevel,
        formatOptions: {
            colors: true
        }
    });

    if (tag) return logger.withTag(tag);
    else return logger;
}
