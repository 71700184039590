import Toast, {type PluginOptions, POSITION } from 'vue-toastification';
import 'assets/css/_toast-override.scss';

export default defineNuxtPlugin(({vueApp})=> {
    const options : PluginOptions = {
        maxToasts: 3,
        position: POSITION.BOTTOM_RIGHT,
        draggable: true,
        draggablePercent: 0.25,
        showCloseButtonOnHover: true,
        newestOnTop: true,
    };
    vueApp.use(Toast, options);
})