<script setup lang="ts">
const appConfig = useAppConfig();
const settings = useSettingsStore();
const constants = useConstants();
const theme = computed(() => settings?.theme || appConfig?.theme.toString() || 'aqua');

const supabase = useSupabaseClient();
const { t, ...i18n } = useI18n();
const { $pwa } = useNuxtApp();

const toast = useToast();

const route = useRoute();

const offlineModeWarned = ref<boolean>(false);

const authUser = useSupabaseUser();
const authCheck = ref<boolean>(false);
const dbStore = useDbStore();
const { profile } = storeToRefs(dbStore);

const conditionProfile = async () => {
  const familyInvitationCode = route.query?.familyIniviteCode;
  if (familyInvitationCode) {
    if (import.meta.client) {
      sessionStorage.setItem('invite-code', familyInvitationCode?.toString());
    }
  }

  if (!profile.value?.is_initialized) {
    return await dbStore.setup();
  }
};

useHead({
  title: t('title'),
  titleTemplate: `%s — ${t('title')} — Modular Family Organizer & Baby Tracker`,
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
    },
    {
      hid: 'description',
      name: 'description',
      content: `A fully Modular Family Organizer & Baby Tracker that enables you to only pay what you use.`
    },
    {
      name: 'theme-color',
      content: '#345ca8'
    },
    {
      name: 'mobile-web-app-capable',
      content: 'yes'
    },
    {
      name: 'twitter:title',
      content: t('title')
    },
    {
      name: 'twitter:description',
      content: `A fully Modular Family Organizer & Baby Tracker that enables you to only pay what you use.`
    },
    {
      name: 'twitter:image',
      content: `${window.location.protocol}//${window.location.host}/icons/pwa-512x512.png`
    },
    {
      name: 'twitter:card',
      content: `app`
    }
  ],
  link: [
    { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    { rel: 'preconnect', href: 'https://fonts.googleapis.com', crossorigin: 'anonymous' },
    { rel: 'icon', type: 'image/svg+xml', href: '/icons/logo.svg' },
    { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700' },
    { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Noto+Emoji:wght@300..700&display=swap' },
    { rel: 'canonical', href: `${window.location.toString()}` }
  ],
  htmlAttrs: {
    'data-theme': theme || 'aqua',
    lang: i18n.locale || 'en'
  },
  script: []
});


onMounted(() => {

  if ($pwa) {
    if ($pwa.offlineReady) {
      toast.warning(`Entering offline-mode`);
      offlineModeWarned.value = true;
    } else if (!$pwa.offlineReady && offlineModeWarned.value) {
      toast.success(`We're back online!`);
      offlineModeWarned.value = false;
    }
  }

  if (route.query['code']) {
    useSmartRedirect();
  }

  watch(authUser, async () => {
    if (authUser.value && authCheck.value == false) {
      authCheck.value = true;
      await conditionProfile();

      if ((profile.value?.is_setup || false) == false) {
        await navigateTo(constants.setupPath);
      }
    }
  }, { immediate: true });

  supabase.auth.onAuthStateChange((event, session) => {
    if (event === 'SIGNED_OUT' && session == null) {
      //@ts-ignore
      sessionStorage.clear();
      navigateTo(constants.loginPath);
    }
    else if (event === 'SIGNED_IN' || event === 'INITIAL_SESSION' || event === 'TOKEN_REFRESHED') {
      const user = useSupabaseUser();
      const { profile } = useDbStore();
      if (user.value && (profile?.is_setup || false) === false) {
        navigateTo(constants.setupPath);
      }
    }
  });

  
});

</script>

<template>
  <div>
    <NuxtLoadingIndicator />
    <VitePwaManifest />   
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<i18n lang="json" locale="en">{
  "title": "FAMFAM"
}</i18n>
