
export const useSmartRedirect = () => {
  const redirectStore = useRedirectStore();
  const { redirect } = storeToRefs(redirectStore);

  if (redirect.value?.fullPath != null) {    
    return navigateTo(redirectStore.getOnce()?.fullPath);
  } else {    
    return navigateTo('/');
  }
};