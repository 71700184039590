import { ThemeNameEnum } from "~~/app.config"

export const useSettingsStore = defineStore('settings', () => {

    const theme = ref<ThemeNameEnum | null>(null);

    const setTheme = (themeName: string) => {

    }

    return {
        theme,

        setTheme
    }
}, {
    persist: {
        storage: persistedState.localStorage
    }
});
